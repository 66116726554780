<template>
  <div class="privacypolicy">
    <top-nav @menu_data="getMenuData" />
    <div class="privacypolicy_content">
      <div class="upper_content">
        <div class="title">Terms and Conditions of Use</div>
        <div class="user_privacy_agreement">
        <div class="privacy_content">
          <div class="title">
            1. Introduction
          </div>
          <div class="content">
              <p>
                <!-- 1.1. Welcome to Arcanite (the 'Application'). The Application provides Real Estates information services (the 'Services'').  -->
              1.1 Welcome to Arcanite. Your access to and use of our website (https://www.arcanite.com.au/), the Arcanite application (<strong>Application</strong>) and any other website or application developed by or for us (together the <strong>Platforms</strong>), and any other services we provide, are subject to these terms and conditions and our Privacy Policy (available <a href="https://arcanite.com.au/PrivacyPolicy" target="_blank">
                  <span>here</span></a>), which together comprise our Terms.
              </p>
              <p>
              <!-- 1.2. The Application is operated by Arcanite Services Pty Ltd (ABN 71 674 390 722). Access to and use of the Application, or any of its associated Products or Services, is provided by Arcanite. Please read these terms and conditions (the 'Terms') carefully. By using, browsing and/or reading the Application, this signifies that you have read, understood and agreed to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Application, or any of its Services, immediately.  -->
              1.2 In these terms and conditions, <strong>we, us, our</strong> or <strong>Arcanite</strong> means Arcanite Services Pty Ltd (ABN 71 674 390 722).
              </p>
              <p>
              1.3 The Application is operated by Arcanite. Access to and use of the Application, or any other Platform, is provided by Arcanite. Please read the Terms carefully. 
              <!-- 1.3. Arcanite reserves the right to review and change any of the Terms by updating this page at its sole discretion. When Arcanite updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.   -->
              </p>
              <p>
              1.4 By using or accessing the Application or any other Platform, this signifies that you have read, understood and agreed to be bound by the Terms in respect of your use of, or access to, the relevant Platform. Users may also accept the Terms by clicking to accept or agree to the Terms where this option is made available by Arcanite in the user interface. If you do not agree with the Terms, you must cease using the Platforms immediately.
              </p>
              <p>
              1.5 Arcanite may vary any of the Terms from time to time. When Arcanite varies the Terms, it will use reasonable endeavours to provide you with notice of the variations. Any variations to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.
              </p>
          </div>
          <div class="title">
          2. IMPORTANT PROVISIONS
          </div>
          <div class="content">
            <p>
              2.1 Please note in particular the provisions in these terms and conditions relating to the rights you grant us to use content that you upload to the Platforms (clause 8), your agreement to indemnify us in the event you breach our Terms (clause 11) and disclaimers and limitations on our liability to you (clause 12).
              <!-- By using or browsing the Application, users automatically accept the Terms. Users may also accept the Terms by clicking to accept or agree to the Terms where this option is made available by Arcanite in the user interface.  -->
              </p>
          </div>
          <div class="title">
          3. Subscription to use the Platforms
          </div>
          <div class="content">
            <p>
              3.1 Before using the Application, you will need to register an account through the Application (<strong>Account</strong>).
              <!-- 3.1. To access the Services, you must first purchase a subscription through the Application (the 'Subscription') and pay the applicable fee for the selected Subscription (the 'Subscription Fee'). -->
            </p>
            <p>
              3.2 As part of the registration process, or your continued use of the Platforms, we may require you to provide personal information about yourself, such as your:
              <!-- 3.2. In purchasing the Subscription, you acknowledge and agree that it is your responsibility to ensure that the Subscription you elect to purchase is suitable for your use. -->
            </p>
            <p>
                (a) First Name and Last Name;
              </p>
              <p>
                (b) Email Address;
              </p>
              <p>
                (c) Password; and
              </p>
              <p>
                (d) Contact Number.
              </p>
            <p>
                3.3 You warrant that any information you give to Arcanite while completing the registration process will always be accurate, correct and up to date.
              <!-- 3.3. Once you have purchased the Subscription, you agree to be bound by the Terms. You will be granted immediate access to the Services from the time you have purchased the Subscription until the Subscription period expires (the 'Subscription Period'). -->
            </p>
              <p>
                3.4 Once you have completed the registration process, you will be a registered member of the Application (<strong>Member</strong>).
                <!-- 3.4. Once you have purchased the Subscription, you will then be required to register for an account through the Application before you can access the Services (the 'Account'). -->
              </p>
              <p>
                3.5 You may not use the Platforms and may not accept the Terms if:
                <!-- 3.5. As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including: -->
              </p>
              <p>
                (a) you are not of legal age to form a binding contract with Arcanite; or
              </p>
              <p>
                (b) you are a person barred from using the Platforms under the laws of Australia, your country of residence or the country from which you use the Platforms.
              </p>
              <!-- <p>
                3.6. You warrant that any information you give to Arcanite while completing the registration process will always be accurate, correct and up to date.
              </p>
              <p>
                3.7. Once you have completed the registration process, you will be a registered member of the Application ('Member').
              </p>
              <p>
                3.8. You may not use the Services and may not accept the Terms if:
              </p>

              <p>
                (a) You are not of legal age to form a binding contract with Arcanite; or 
              </p>
              <p>
                (b) You are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.
              </p> -->
          </div>
          <div class="title">
            4. Your obligations
          </div>
          <div class="content">
            <p>
              <!-- 4.1. As a Member, you agree to comply with the following: -->
              4.1 You:
            </p>
            <p>
            (a) must use the Platforms only for purposes that are permitted by:
            </p>
            <p>
              (i) the Terms; and 
            </p>
            <p>
              (ii) any applicable law.
      
            </p>
            <p>
            (b) are solely responsible for all activity that occurs on your Account;
            </p>
            <p>
            (c) have sole responsibility for protecting the confidentiality of your password, username and other log in details related to the Account;
            </p>
            <p>
            (d) must ensure that no other person uses your registration information;
            </p>
            <p>
            (e) must immediately notify Arcanite of any unauthorised use of your password or username or other log in details or any breach of security of which you become aware;
            </p>
            <p>
            (f) must ensure that all information you provide to us on the Platforms, including on your Account, is correct, accurate and current;
            </p>
            <p>
            (g) must not use the Platforms for any illegal and/or unauthorised use which includes collecting email addresses of other users by electronic or other means for the purpose of sending unsolicited email or unauthorised framing of or linking to the Platforms;
            </p>
            <p>
            (h) must not to use the Platforms in any way that is illegal, that will infringe the rights of any third party or that breaches our Terms;
            </p>
            <p>
            (i) must not reproduce, exploit, modify, reverse engineer or create derivative works of the Platforms or the materials on them;
            </p>
            <p>
            (j) must not copy, share or insert a link to the Platforms for the purpose of suggesting that you or a third party has a sponsorship arrangement, affiliation or other connection with us, unless you have our prior written consent to do so;
            </p>
            <p>
            (k) must not use the Platforms in any way that causes, or is likely to cause, the Platforms or access to them to be interrupted, damaged or impaired in any way, or to introduce computer viruses, spyware programs, or other software which may affect or compromise the security or availability of the Platforms;
            </p>
            <p>
            (l) acknowledge that Arcanite will take appropriate legal action for any illegal or unauthorised use of the Platforms; and
            </p>
            <p>
            (m) acknowledge and agree that any automated use of the Platforms is prohibited.
            </p>
          </div>
          <div class="title">
            5. Third party sellers
          </div>
          <div class="content">
            <p>
            5.1.  You acknowledge and agree that:
            </p>
            <p>
                (a) for any properties that are displayed on the Platform, we are not the seller of the properties;
            </p>
            <p>
                (b) the properties are being offered for sale by third party sellers (and not by us); and
            </p>
            <p>
                (c) we are not responsible for the images, photographs, descriptions, prices or other information displayed on the Platform in connection with a property displayed on the Platform (including information as to whether the property is available) (<strong>Property Details</strong>) and we rely on the third party sellers of the properties to provide us with such Property Details.
            </p>
            <p>
              5.2 We require the third party sellers of the properties displayed on the Platform to ensure that the Property Details provided to us are and remain accurate, complete, current and not misleading. However, as we are reliant on the third party sellers to ensure that this is the case, to the maximum extent permitted by law, we:
            </p>
            <p>
                (a) cannot and do not provide any guarantees, representations or warranties with respect to the accuracy, completeness or currency of such Property Details; and
            </p>
            <p>
                (b) exclude any and all responsibility or liability in the event that the Property Details are inaccurate, incomplete, out of date or misleading in any way.
            </p>
            <p>
              5.3 You agree that, to the extent you rely on the Property Details, you do so at your own sole risk.
            </p>
            <p>
              5.4 We will not be responsible or liable for any properties displayed on the Platform that you purchase from third party sellers. Such responsibility and liability will remain with the third party sellers of the relevant properties.
            </p>
            <p>
              5.5 To the maximum extent permitted by law, we also do not provide any guarantees, warranties or representations that any properties displayed on the Platform will be fit for purpose.
              <!-- 5.5 We will not be responsible or liable for any properties displayed on the Platform that you purchase from third party sellers. Such responsibility and liability will remain with the third party sellers of the relevant properties. -->
            </p>
          </div>
          <!-- wwj -->
          <div class="title">
            6. Payments
          </div>
          <div class="content">
            <!-- <p>
              Arcanite will only provide you with a refund of the Subscription Fee in the event they are unable to continue to provide the Services or if the manager of Arcanite decides, at its absolute discretion, that it is reasonable to do so under the circumstances. Where this occurs, the refund will be in the proportional amount of the Subscription Fee that remains unused by the Member (the 'Refund').
            </p> -->
            <p>
              6.1 All payments you make during your use of the Platforms will be made using Stripe, a third party service provider. You acknowledge and agree that you are responsible for reading and ensuring you understand and agree to be bound by the Stripe terms and conditions and privacy policy, which are available on their website, before making a payment on any of the Platforms.
            </p>
            <p>
              6.2 You acknowledge that we do not control or have any responsibility for Stripes terms and conditions or privacy practices.
            </p>
          </div>
          <div class="title">
            7. Intellectual Property in Our Content
          </div>
          <div class="content">
            <p>
              7.1 The Platforms and all related products of Arcanite are subject to copyright. Unless otherwise indicated, all rights (including copyright) in the Platforms (including but not limited to text, graphics, logos, button icons, video images, audio clips, application, code, scripts, design elements and interactive features) and our trade marks, service marks and trade names (<strong>Our Content</strong>) are owned by or licensed to Arcanite.
              <!-- 7.1. The Application, the Services and all the related products of Arcanite are subject to copyright. The material on the Application is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Application (including but not limited to text, graphics, logos, button icons, video images, audio clips, application, code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes and are reserved by Arcanite or its contributors. -->
            </p>
            <p>
              7.2 In consideration of you agreeing to our Terms, we grant you a non-exclusive, royalty-free, revocable, non-transferrable, non-sublicenseable licence to use the Platforms and Our Content in accordance with our Terms whilst you are a Member to:
            <!-- 7.2. All trademarks, service marks and trade names are owned, registered and/or licensed by Arcanite, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a Member to: -->
            </p>
              <p>
              (a) use the Platforms pursuant to the Terms; and
              </p>
              <p>
              (b) print pages from the Platforms for your own personal and non-commercial use. 
              </p>
              <!-- <p>
              (c) Print pages from the Application for your own personal and non-commercial use. Arcanite does not grant you any other rights whatsoever in relation to the Application or the Services. All other rights are expressly reserved by Arcanite.
              </p> -->
            <p>
              <!-- 7.3.Arcanite retains all rights, title, and interest in and to the Application and all related Services. Nothing you do on or in relation to the Application will transfer any: -->
              7.3 Arcanite retains all rights, title, and interest in and to the Platforms and all related services. Nothing in our Terms and nothing you do on or in relation to the Platforms constitutes, or may be construed as, a transfer of any intellectual property or other rights in the Platforms or Our Content to you.
            </p>
            <p>
              7.4 You must not, without our prior written consent, broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Platforms for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Platforms which are freely available for re-use or are in the public domain.
              <!-- 7.4.You may not, without the prior written permission of Arcanite and the permission of any other relevant rights owners, broadcast, republish, up-load to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Application, which are freely available for re-use or are in the public domain. -->
            </p>
            <p>
              7.5 You must not reproduce, sell, license, or otherwise exploit the Platforms or Our Content, unless you have our prior written consent to do so.
            </p>
          </div>
          <div class="title">
            8. User Content
          </div>
          <div class="content">
          <p>
            8.1 You acknowledge that:
            <!-- Arcanite takes your privacy seriously and any information provided through your use of the Application and/or Services are subject to Arcanite Privacy Policy, which is available on the Application. -->
          </p>
          <p>
            (a) the Platforms may include information and materials uploaded by other users of the Platforms; and
            <!-- Arcanite takes your privacy seriously and any information provided through your use of the Application and/or Services are subject to Arcanite Privacy Policy, which is available on the Application. -->
          </p>
          <p>
            (b) this information and these materials have not been verified or approved by us and we take no responsibility for the accuracy, completeness or currency of such information and materials.
          </p>
          <p>
            8.2 To the extent we allow you to post, upload, transmit or otherwise make available any information, images, video, suggestions, comments, improvements, ideas, feedback or other data on the Platforms (<strong>Your Content</strong>), you agree that:
          </p>
          <p>
            (a) you are solely responsible for Your Content that you post, upload, transmit or otherwise make available;
          </p>
          <p>
            (b) you are solely responsible for securing and backing up Your Content;
          </p>
          <p>
            (c) you will not share anything which you do not have the permission or right to share; and
          </p>
          <p>
            (d) you grant us a worldwide, non-exclusive, perpetual, irrevocable, royalty free and sub-licensable licence to reproduce, modify, exploit, display, distribute and otherwise disclose to third parties Your Content for any purpose, including (without limitation) for the purposes of advertising and marketing Arcanite and/or the Platforms, without further notice to or consent from you.
          </p>
          <p>
            8.3 We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our Platforms constitutes a violation of their intellectual property rights, or of their right to privacy.
          </p>
          <p>
            8.4 We are not responsible, or liable to any third party, for the content or accuracy of any content posted by you or any other user of the Platforms.
          </p>
          <p>
            8.5 We may remove, refuse to post or take any action with respect to any of Your Content for any or no reason in our sole discretion.
          </p>
          </div>
          <div class="title">
            9. Communication
          </div>
          <div class="content">
            <p>
              9.1 We may send you email updates, reminders, promotional offers and related material. All such communication will include the option to unsubscribe at any time.

            <!-- 9.1. Nothing in the Terms limits or excludes any guarantees, warranties, representations, or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded. -->
            </p>
            <p>
              9.2 From time to time, we may send you communications on behalf of our third party partners, which we believe may be relevant and of interest to you. These communications will also include the option to unsubscribe at any time.
            <!-- 9.2. Subject to this clause, and to the extent permitted by law: -->
            </p>
          </div>
          <div class="title">
            10. Privacy
          </div>
          <div class="content">
            <p>
              10.1 Arcanite takes your privacy seriously and will handle any personal information you provide to us in accordance with our Privacy Policy, which is available <a href="https://arcanite.com.au/PrivacyPolicy" target="_blank">
              <span>here</span></a>.
            <!-- If you are in the business of providing similar Services for the purpose of providing them to users for a commercial gain, whether business users or domestic users, then you are a competitor of Arcanite. Competitors are not permitted to use or access any information or content on our application. If you breach this provision, Arcanite will hold you fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach. -->
            </p>
            <p>
              10.2 If you provide to us or upload to a Platform personal information of or relating to another person, you represent and warrant that:
            </p>
            <p>
              (a) you have made all necessary notifications to, and obtained all necessary consents from, the individuals whose personal information you are providing or uploading for the purposes for which you have provided or uploaded the information; and
            </p>
            <p>
              (b) you will comply with all applicable requirements under the <i>Privacy Act 1988</i> (Cth) in respect of the provision or disclosure of such personal information.
            </p>
          </div>
          <div class="title">
            11. Indemnity
          </div>
          <div class="content">
            <p>
            11.1. You agree to indemnify Arcanite, its affiliates, employees, agents, contributors, third party content providers and licensors from and against all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with:
            </p>
            <p>
              (a) Your Content;
            </p>
            <p>
              (b) your misuse of the Platforms; or 
            </p>
            <p>
              (c) your breach of the Terms,
            </p>
            <p>
              except to the extent we cause or contribute to the claim, loss or liability.
            </p>
            
          
          </div>
          <div class="title">
            12. Disclaimers and limitation of liability
          </div>
          <div class="content">
            <p>12.1. Nothing in the Terms limits or excludes any guarantees, warranties, representations, or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.
            </p>
            <p>12.2. To the extent permitted by law:
            </p>
            <p>(a) all terms, guarantees, warranties, representations, or conditions which are not expressly stated in the Terms are excluded; and
            </p>
            <p>
            (b) Arcanite will not be liable for any:
            </p>
            <p>
              (i) special, indirect or consequential loss or damage;
            </p>
            <p>
              (ii) loss of profit or opportunity; or
            </p>
            <p>
              (iii) damage to goodwill arising out of, or in connection with, the Platforms or these Terms,
            </p>
            <p>
              whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.
            </p>
            <p>
              12.3 Use of the Platforms is at the user's own risk. Everything on the Platforms is provided "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors, and licensors of Arcanite make any express or implied representation or warranty about the Platforms or any products or services on the Platforms. 
            </p>
            <p>
              12.4 Without limiting clause 12.3, Arcanite excludes liability for any loss or damage you might suffer due to any of the following:
            </p>
            <p>
              (a) any contract of sale in relation to a property displayed on the Application not completing as a result of or in connection with the Application (including as a result of the Application not being continuously accessible or available);
            </p>
            <p>
              (b) failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful components, loss of data, communication line failure, unlawful third-party conduct, or theft, destruction, alteration or unauthorised access to records affecting or relating to the Platforms;
            </p>
            <p>
              (c) the accuracy, suitability or currency of any information on the Platforms (including, but not limited to, the Property Details), or any related products or services (including third party material and advertisements on the Platforms); and
            </p>
            <p>
              (d) costs incurred as a result of using the Platforms or any other products or services of Arcanite.
            </p>
            <p>
              12.5 You may see advertising material of third parties on our Platforms. Each advertiser is solely responsible for the content of its advertising material and we accept no responsibility for the content of such advertising material, including, without limitation, any error, omission or inaccuracy in such content.
            </p>
            <p>
              12.6 We may restrict, suspend or terminate your access to the Platforms or any feature of the Platforms for security, operational or maintenance reasons without notice. We may revise the content of, amend links on, or withdraw access to, the Platforms without notice. We will not be responsible for any loss, cost, damage or liability that may arise as a result.
            </p>
            <p>
              12.7 If we are liable to you in relation to a failure to comply with a guarantee that applies under the Australian Consumer Law that cannot be excluded, our total liability to you for that failure is limited to, at our option:
            </p>
            <p>
              (a) in the case of services, the resupply of the services or the payment of the cost of resupply; and
            </p>
            <p>
              (b) in the case of goods, the replacement of the goods or the supply of equivalent goods, or the repair of the goods, or the payment of the cost of replacing the goods or of acquiring equivalent goods, or the payment of the cost of having the goods repaired.
            </p>
          </div>
            <div class="title">
            13. Links 
          </div>
          <div class="content">
            <p>
            13.1 The Platforms may contain links to other websites (<strong>Linked Websites</strong>) which we do not control. We do not make any representations or provide any warranties about the currency or accuracy of the content on those Linked Websites. We are not responsible or liable for the content or privacy practices of those Linked Websites.
            </p>
            <p>
            13.2 You must not establish a link to any of our Platforms in such a way as to suggest any form of association, approval or endorsement with or by us, without our prior written approval.
            </p>
            <p>
            13.3 We may withdraw any linking permission we have provided without notice.
            </p>
          </div>
          <div class="title">
            14. Competitors
          </div>
          <div class="content">
            <p>
              14.1 If you are in the business of providing similar services to those offered in the Application for a commercial gain, then you are a competitor of Arcanite. Competitors must not use or access any information or content on our Application without our express prior written consent. If you breach this provision, Arcanite will hold you fully responsible for any loss that we may sustain and hold you accountable for all profits that you might make from such a breach.
            </p>
          </div>
          <div class="title">
          15. Termination
          </div>
          <div class="content">
            <p>
              15.1 We may at any time, terminate your entitlement to access and use the Platforms if:
            </p>
            <p>
              (a) you breach any provision of the Terms or any applicable law; 
            </p>
            <p>
              (b) your rights to access or use any of the Platforms cease by operation of any other agreement to which we are a party;
            </p>
            <p>
              (c) your use of the Platforms (or any part of them) adversely affects another user's use of the Platforms (or any part of them) or
            </p>
            <p>
              (d) we are required to do so by law.
            </p>
            <p>
              15.2 Subject to applicable laws, we reserve the right to discontinue, cancel or suspend your membership or access to the Platforms (or any part of them) at any time.
            </p>
          </div>
          <div class="title">
            16. Governing Law
          </div>
          <div class="content">
            <p>
              16.1 The Terms are governed by the laws of New South Wales, Australia. The parties agree to submit to the exclusive jurisdiction of the courts and tribunals of New South Wales, Australia.
              <!-- The Terms are governed by the laws of New South Wales, Australia. Any dispute, controversy, proceeding or claim of whatever nature arising out of, or in relation to the Terms and the rights created hereby, shall be governed, interpreted, and construed under and pursuant to the laws of New South Wales, Australia, without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns. -->
            </p>
          </div>
          <div class="title">
          17. Severance
          </div>
          <div class="content">
            <p>
              17.1 If any part of these Terms is found to be void or unenforceable by a court of competent jurisdiction, that part shall be severed, and the rest of the Terms shall remain in force.
              <!-- Both parties confirm and declare that the provisions of the Terms are fair and reasonable. Both parties have taken the opportunity to obtain independent legal advice and declare the Terms are not against public policy, on the grounds of inequality or bargaining power or general grounds of restraint of trade.
            If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed, and the rest of the Terms shall remain in force. -->
            </p>
          </div>
          <div class="content" style="marginTop:20px"><p>Date of Last Revision: Jul 15th, 2022</p>
          </div>
          
          <!-- -->
          </div>
        </div>
      </div>
      </div>
      <div class="bottom_link">
        <!-- <span>Copyright 2021 © Arcanite</span>
        <span @click="goRouter('/privacyPolicy')">Privacy Policy</span>
        <span @click="goRouter('/termsAndConditions')">Terms & Conditions</span> -->        
      </div>
    <!-- </div> -->
    <footer-nav/>
    <menu-bar :isShow="isShow" @changeIsShow="changeShow" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isShow: false
    };
  },
  created() {},
  methods: {
    goRouter(path) {
      this.$router.push(path);
    },
    getMenuData(val) {
      val == "1" ? (this.isShow = true) : (this.isShow = false);
    },
    changeShow() {
      this.isShow = false;
    }
  },
  components: {
    "top-nav": resolve => require(["./TopNav.vue"], resolve),
    "menu-bar": resolve => require(["./MenuBar.vue"], resolve),
    "footer-nav": resolve => require(["./FooterNav.vue"], resolve)
  }
};
</script>
<style lang="scss" scoped>
.privacypolicy {
  .privacypolicy_content {
    background-color: #f7f7f7;
    margin-top: 103px;
    box-sizing: border-box;
    // height: calc(100vh - 103px);
    padding: 29px 25px 27px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .upper_content {
      .title {
        text-align: left;
        font-family: Poppins;
        font-size: 24px;
        color: #000;
      }
      // .content {
      //   margin-top: 20px;
      //   text-align: left;
      //   font-family: Poppins;
      //   font-size: 14px;
      //   line-height: 1.43;
      //   letter-spacing: 0.23px;
      //   color: #1e1d1e;
      // }
      .user_privacy_agreement {
        margin-top: 20px;
        text-align: left;
        font-family: Poppins;
        font-size: 14px;
        line-height: 1.43;
        letter-spacing: 0.23px;
        color: #1e1d1e;
        // padding: 20px;
        .title {
          text-align: left;
          font-size: 14px;
          font-family: Poppins, Helvetica, Arial,
            sans-serif;
          font-weight: bold;
          color: rgba(55, 55, 67, 1);
          padding: 20px 0;
        }
        .content {
          font-size: 14px;
          font-family: Poppins, Helvetica, Arial,
            sans-serif;
          font-weight: 400;
          color: rgba(83, 84, 93, 1);
          line-height: 1.5;
          > ul {
            li {
              list-style: inside;
            }
          }
        }
      }
    }
    .bottom_link {
      font-family: Arial;
      font-size: 12px;
      color: #9b9b9b;
      text-align: left;
      span {
        margin-right: 25px;
      }
    }
  }
}
</style>